import React, { useEffect } from 'react';

const AnimalMerge = () => {
  useEffect(() => {
    // Check the protocol and alert the user if the protocol is 'file'
    if (window.location.protocol.startsWith("file")) {
      alert(
        "Web exports won't work until you upload them. (When running on the file: protocol, browsers block many features from working for security reasons.)"
      );
    }

    // Dynamically load external scripts after component mounts
    const loadScript = (src, type = 'text/javascript') => {
      const script = document.createElement('script');
      script.src = src;
      script.type = type;
      script.async = true;
      document.body.appendChild(script);
    };

    // Load external JS files
    loadScript('/scripts/supportcheck.js');
    loadScript('/scripts/main.js', 'module');

    // Remove noscript message if JavaScript is enabled
    const noscriptElement = document.getElementById('noscript');
    if (noscriptElement) {
      noscriptElement.style.display = 'none';
    }

    // After canvas is loaded, center it
    const handleCanvasPositioning = () => {
      const canvas = document.querySelector('canvas');
      if (canvas) {
        // Apply absolute positioning and center the canvas
        canvas.style.position = 'absolute';
        canvas.style.top = '56%';
        canvas.style.left = '50%';
        canvas.style.transform = 'translate(-50%, -50%)';
      }
    };

    // Call handleCanvasPositioning after a slight delay to ensure canvas is loaded
    setTimeout(handleCanvasPositioning, 500); // Adjust delay as necessary

  }, []);

  return (
    <div>
      {/* You can add any additional content here */}
    </div>
  );
};

export default AnimalMerge;
